import React, { useState } from "react"
import Layout from "../components/layout"
import Seo from "../components/SEO/SEO"
import HeaderBeDigital from "../components/headerBeDigital"
import Boton from "../components/boton"
import "../styles/bedigital.scss"
import "../styles/blackfriday.scss"
import "../styles/contacto.scss"
import { IconContext } from "react-icons"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"

import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

export default function BlackFriday(props) {
  const [nombre, setNombre] = useState("")
  const [empresa, setEmpresa] = useState("")
  const [email, setEmail] = useState("")
  const [telefono, setTelefono] = useState("")
  const [mensaje, setMensaje] = useState(
    "Quiero beneficiarme del descuento del 30% en auditorías"
  )
  const [isChecked, setIsChecked] = useState(false)
  const [mensajeError, setMensajeError] = useState("")
  const [respuesta, setRespuesta] = useState("")

  const { executeRecaptcha } = useGoogleReCaptcha()

  const handleSubmit = async e => {
    e.preventDefault()
    // Check if the captcha was skipped or not
    if (!executeRecaptcha) {
      return
    }

    // This is the same as grecaptcha.execute on traditional html script tags
    const result = await executeRecaptcha("leadblackfriday")
    //--> grab the generated token by the reCAPTCHA
    // Prepare the data for the server, specifically body-parser

    const data = JSON.stringify({
      nombre,
      empresa,
      email,
      telefono,
      mensaje,
      result,
    })
    // POST request to your server

    fetch(`${process.env.API_URL}/blackfridays`, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-type": "application/json",
      },
      body: data, //--> this contains your data
    })
      .then(res => res.json())
      .then(data => {
        const success = data.success
        const score = data.score

        if (success && score >= 0.7) {
          setMensajeError("Gracias por ponerte en contacto con nosotros")
          setRespuesta("correcto")
          setNombre("")
          setEmpresa("")
          setEmail("")
          setTelefono("")
          setMensaje("Quiero beneficiarme del descuento del 30% en auditorías")
          setIsChecked(false)
        } else {
          setMensajeError(
            "Ha habido un error en el envío del formulario, por favor inténtalo de nuevo"
          )
          setRespuesta("error")
        }
      })
  }

  return (
    <Layout>
      <Seo
        title="Oferta Black Friday"
        pathname={props.location.pathname}
        imagen="https://bullhost.security/img/cabeceras/blackfriday.jpg"
        robots="noindex,follow"
      />
      <div className="cabecera-bedigital">
        <HeaderBeDigital
          titulo="Aprovéchate vilmente de nuestro Black Friday"
          imagen="/img/cabeceras/blackfriday.jpg"
        />
        <div className="intro-bedigital">
          <p>
            Hasta el 30 de noviembre de 2021 puedes obtener un 30% de descuento
            en todas nuestras auditorías de ciberseguridad.
          </p>
        </div>
      </div>
      <div className="cuerpo-servicio">
        <section className="limitador contenido__servicio">
          <h2 className="titulo-be">Quiero ser un villano.</h2>
          <div className="bloque-noasistir">
            <div className="prueba-demo blackfriday">
              <div className="bloque-noasistir-p">
                <p className="bloque-noasistir-p texto-cta">
                  Y aprovecharme del 30% de descuento en la auditoría de
                  ciberseguridad para mi empresa.
                </p>
                <img
                  src="/img/descuento-black-friday.jpg"
                  alt="Descuento por el black friday"
                />
              </div>
              <div className="cuerpo-contacto bloque-noasistir-div">
                <div className="caja-formulario">
                  <form
                    className="form-contacto"
                    method="post"
                    autoComplete="off"
                    onSubmit={handleSubmit}
                  >
                    <label htmlFor="nombre">Nombre</label>
                    <input
                      name="nombre"
                      placeholder="Nombre"
                      required
                      value={nombre}
                      onChange={e => setNombre(e.target.value)}
                    />

                    <label htmlFor="nombre">Empresa</label>
                    <input
                      name="empresa"
                      placeholder="Empresa"
                      required
                      value={empresa}
                      onChange={e => setEmpresa(e.target.value)}
                    />

                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      name="email"
                      placeholder="Email"
                      required
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                    />

                    <label htmlFor="telefono">Teléfono</label>
                    <input
                      type="tel"
                      name="telefono"
                      placeholder="Teléfono (opcional)"
                      maxLength="14"
                      minLength="9"
                      value={telefono}
                      onChange={e => setTelefono(e.target.value)}
                    />

                    <label htmlFor="mensaje">Teléfono</label>
                    <textarea
                      name="mensaje"
                      id="mensaje"
                      cols="30"
                      rows="5"
                      value={mensaje}
                      onChange={e => setMensaje(e.target.value)}
                    ></textarea>
                    <div className="caja-checkbox">
                      <input
                        type="checkbox"
                        id="privacidad"
                        name="privacidad"
                        // defaultChecked={isChecked}
                        checked={isChecked}
                        onChange={() => setIsChecked(!isChecked)}
                      />
                      <label htmlFor="privacidad" className="label-check">
                        He leído y acepto la{" "}
                        <Link to="/politica-de-privacidad/">
                          política de privacidad
                        </Link>
                      </label>
                    </div>

                    <div
                      className={`mensaje-formulario
                                ${respuesta}                              
                            `}
                      dangerouslySetInnerHTML={{ __html: mensajeError }}
                    />

                    <IconContext.Provider value={{ color: "#1f1f1f" }}>
                      <input
                        type="submit"
                        value="Enviar"
                        className="boton-enviar"
                        disabled={!isChecked}
                      />
                    </IconContext.Provider>
                    <p className="texto-recaptcha">
                      Este sitio está protegido por reCAPTCHA y se aplican la
                      <a href="https://policies.google.com/privacy">
                        política de privacidad
                      </a>
                      y los
                      <a href="https://policies.google.com/terms">
                        términos del servicio
                      </a>
                      de Google.
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}
